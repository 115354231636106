import React, { useState, useEffect, useLayoutEffect } from "react";
import { fabric } from "fabric";
import { hexToRgb } from "../helpers/hextoRgb";
import { getRequest } from "../hooks/axiosClient";
import { addVerticalPin } from "./pins";
import { escapeSpecialCharacters } from "../helpers/escapeSpecialCharacters";
import "../App.css";
import {
  ChangeSvgColorPassingBE,
  addApointToRotate,
  applyZoom,
  canvasBackGroundColor,
  canvasBackGroundImage,
  getAngleBetweenTouches,
  getSquareCoordinates,
  pinNameTextBringtoFront,
  reinitialiseFabric,
  removeFabricObjectsByName,
  rotateCanvas,
} from "../helpers/CanvasConstants.js/canvasFunctions";
import {
  renderAmenities,
  renderBeacons,
  renderLocations,
  renderProducts,
  renderSafeties,
  renderTexts,
  renderTracingCircle,
  renderTracings,
  renderVerticalTransport,
} from "../helpers/CanvasConstants.js/canvasObjs";
import SetDestination from "./SetDestination";
import SetProjuctDestination from "./setProductDestination";
import SetLocation from "./showlocation";
import floorplanDummy from '../assets/img/floorplan.png'
import { environmentaldatas } from "../constant/defaultValues";

const { image_url } = environmentaldatas

const FabricCanvas = ({
  currentFloor,
  from,
  to,
  page,
  canvas,
  setCanvas,
  setFromDestinationData,
  setCurrentFloor,
}) => {
  let prevPointer;
  const project = localStorage.getItem("project_data");
  const [projectSettings, setProjectSettings] = useState({});
  const [canvasOuterPoints, setCanvasOuterPoints] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });

  //   pop up states
  const [openLocation, setOpenLocation] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openOtherPins, setOpenOtherPins] = useState(false);
  const [zooming, setZooming] = useState(false);
  const [otherPinType, setOtherPinType] = useState();
  const [id, setId] = useState();
  const close = () => {
    setOpenLocation(false);
  };

  // variables for canvas rotation
  let initialAngle = 0;
  let initialTouchAngle = 0;

  window.addEventListener("resize", () => {
    if (canvas) {
      const height =
        page === "report" ? window.innerHeight - 260 : window.innerHeight - 195;
      const width = window.innerWidth;
      canvas.setHeight(height);
      canvas.setWidth(width);
      canvas.renderAll();
    }
  });

  const initialiseFabric = () => {
    let isDragging = false;
    let lastX = 0;
    let lastY = 0;
    let lastZoom = 1;

    let isZooming = false;
    let initialPinchDistance = 0;
    let pinchCenterX = 0;
    let pinchCenterY = 0;

    let clicked = undefined

    // variables for canvas rotation
    // canvas rotation functions
    let initialAngle = 0;
    let initialTouchAngle = 0;
    // -----------------------

    const canvas = new fabric.Canvas("canvas", {
      height:
        page == "report" ? window.innerHeight - 260 : window.innerHeight - 195,
      width: window.innerWidth,
      // backgroundColor: projectSettings?.background_color ? hexToRgb(projectSettings?.background_color) : '#F6F7F3',
      backgroundColor: projectSettings?.background_color ?? "#F6F7F3",
      preserveObjectStacking: true,
      allowTouchScrolling: false,
    });

    canvas.requestRenderAll();
    canvas.on("mouse:wheel", function (options) {
      const delta = options.e.deltaY;
      var zoom = canvas.getZoom();
      if (delta > 0) {
        zoom /= 1.1;
      } else {
        zoom *= 1.1;
      }
      console.log(canvas.getActiveObject());

      applyZoom(canvas, options.e.offsetX, options.e.offsetY, zoom, "mouse");
      // applyZoom(canvas, options.e.offsetX, options.e.offsetY, zoom, 'mouse');
    });

    canvas.on("mouse:down", function (opt) {
      // const pol = canvas.getObjects().forEach((obj) => {
      //   console.log(obj,obj?.name)
      // })

      clicked = opt

      isDragging = true;
      canvas.selection = false;
      if (opt.e.type === "touchstart") {
        if (opt.e.touches.length === 1) {
          lastX = opt.e.touches[0].clientX;
          lastY = opt.e.touches[0].clientY;


        } else if (opt.e.touches.length === 2) {
          closeOpenedPopUps()
          const centerX =
            (opt.e.touches[0].clientX + opt.e.touches[1].clientX) / 2;
          const centerY =
            (opt.e.touches[0].clientY + opt.e.touches[1].clientY) / 2;
          pinchCenterX = centerX;
          pinchCenterY = centerY;

          const deltaX = opt.e.touches[0].clientX - opt.e.touches[1].clientX;
          const deltaY = opt.e.touches[0].clientY - opt.e.touches[1].clientY;
          initialPinchDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        }
      }
    });

    canvas.on(
      "mouse:move",
      throttle(function (evt) {
        if (evt.e.touches && evt.e.touches.length === 2) {
          isZooming = true;
          const currentPinchDistance = Math.hypot(
            evt.e.touches[0].clientX - evt.e.touches[1].clientX,
            evt.e.touches[0].clientY - evt.e.touches[1].clientY
          );
          const delta = currentPinchDistance - initialPinchDistance;
          var zoom = canvas.getZoom() * (1 + delta / 1000);
          // var zoom = canvas.getZoom();
          if (delta > 0) {
            // zoom += (delta * 0.1);
            zoom += 0.2;
          } else {
            // zoom -= (delta * 0.1);
            zoom -= 0.2;
          }
          const centerX =
            (evt.e.touches[0].clientX + evt.e.touches[1].clientX) / 2;
          const centerY =
            (evt.e.touches[0].clientY + evt.e.touches[1].clientY) / 2;
          pinchCenterX = centerX;
          pinchCenterY = centerY;
        }

        if (isDragging && !isZooming) {
          if (evt.e.type === "mousemove") {
            const delta = new fabric.Point(evt.e.movementX, evt.e.movementY);
            var pointer = canvas.getPointer(evt.e);
            const viewBoundary = canvas.calcViewportBoundaries();
            let boundPoints = {};
            setCanvasOuterPoints((prev) => {
              boundPoints = prev;
              return prev;
            });
            if (
              (viewBoundary.tl.x >= boundPoints.left &&
                prevPointer?.x > pointer.x) ||
              (viewBoundary.tr.x <= boundPoints.right &&
                prevPointer?.x < pointer.x) ||
              (viewBoundary.bl.y > boundPoints.bottom &&
                prevPointer?.y > pointer.y)
            ) {
              return;
            }
            canvas.relativePan(delta);
            prevPointer = pointer;
          } else if (evt.e.type === "touchmove" && evt.e.touches.length === 1) {
            const deltaX = evt.e.touches[0].clientX - lastX;
            const deltaY = evt.e.touches[0].clientY - lastY;
            const delta = new fabric.Point(deltaX, deltaY);
            canvas.relativePan(delta);
            lastX = evt.e.touches[0].clientX;
            lastY = evt.e.touches[0].clientY;
          }
        }
        return;
      }, 20)
    );

    canvas.on("mouse:up", function () {
      isDragging = false;
      isZooming = false;
      initialPinchDistance = 0;
      console.log(clicked)
      if (clicked) {
        clickhandler(clicked, clicked?.target);
      }
    });

    // function applyZoom(centerX, centerY, zoom, type) {
    //     canvas.selection = false;
    //     if (zoom > 20) return (zoom = 20);
    //     if (zoom < 0.05) return (zoom = 0.05);
    //     canvas.forEachObject((obj) => {
    //         const center = obj.getCenterPoint();

    //         if (
    //             obj.name != "short_path" &&
    //             obj.name != "tracing" &&
    //             obj.name != "boundary" &&
    //             obj.name != "text"
    //         ) {
    //             if (obj.type == "group" && !obj?.id && obj.name != "text") {

    //                 obj.forEachObject((obj1) => {
    //                     obj1.set({
    //                         ignoreZoom: true,
    //                         skipAbsolute: true,
    //                         scaleX: 1 / canvas?.getZoom(),
    //                         scaleY: 1 / canvas?.getZoom(),
    //                         strokeWidth: 1
    //                     });
    //                 });
    //             }
    //             else {
    //                 obj.set({
    //                     ignoreZoom: true,
    //                     skipAbsolute: true,
    //                     scaleX: 1 / canvas?.getZoom(),
    //                     scaleY: 1 / canvas?.getZoom()
    //                 });
    //             }
    //             obj.setPositionByOrigin(center, "center", "center");
    //             canvas.requestRenderAll();
    //         }

    //         /* Adjust opacity of pins in zoom out */
    //         if (obj?.name != "tracing" &&
    //             obj?.types != 'highlight_pin' &&
    //             obj?.name != "short_path" &&
    //             obj?.name != "text" &&
    //             obj?.name != "safety" &&
    //             obj?.name != "amenity" &&
    //             obj?.name != "vertical" &&
    //             obj?.name != "boundary" &&
    //             canvas.getZoom() <= 0.35
    //         ) {
    //             if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
    //                 console.log(zoom, '0.6')
    //                 obj.set({ opacity: 0.6 })
    //             } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
    //                 obj.set({ opacity: 0.3 })
    //                 console.log(zoom, '0.3')
    //             } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
    //                 obj.set({ opacity: 0.1 })
    //                 console.log(zoom, '0.1')
    //             } else if (canvas.getZoom() < 0.1) {
    //                 console.log(zoom, '0')
    //                 obj.set({ opacity: 0 })
    //             }
    //         } else if (obj?.name != "boundary") {
    //             obj.set({ opacity: 1 })
    //         }
    //         canvas.requestRenderAll();
    //     });

    //     if (type == "mouse") {
    //         canvas.zoomToPoint({ x: centerX, y: centerY }, zoom);
    //     } else {
    //         const easingFactor = 0.3;
    //         const smoothZoom =
    //             canvas.getZoom() + (zoom - canvas.getZoom()) * easingFactor;
    //         canvas.zoomToPoint({ x: centerX, y: centerY }, smoothZoom);
    //     }

    //     lastZoom = zoom;
    // }

    function applyPinchZoom(centerX, centerY, zoom, type) {
      canvas.forEachObject((obj) => {
        const center = obj.getCenterPoint();

        if (
          obj.name != "short_path" &&
          obj.name != "svg_refImage" &&
          obj.name != "tracing" &&
          obj.name != "boundary" &&
          obj.name != "text"
        ) {
          if (obj.type == "group" && !obj?.id && obj.name != "text") {
            obj.forEachObject((obj1) => {
              obj1.set({
                ignoreZoom: true,
                skipAbsolute: true,
                scaleX: 1 / canvas?.getZoom(),
                scaleY: 1 / canvas?.getZoom(),
                strokeWidth: 1,
              });
            });
          } else {
            obj.set({
              ignoreZoom: true,
              skipAbsolute: true,
              scaleX: 1 / canvas?.getZoom(),
              scaleY: 1 / canvas?.getZoom(),
            });
          }
          obj.setPositionByOrigin(center, "center", "center");
          canvas.requestRenderAll();
        }

        if (
          obj?.name != "tracing" &&
          obj.name != "svg_refImage" &&
          obj?.types != "highlight_pin" &&
          obj?.name != "short_path" &&
          obj?.name != "text" &&
          obj?.name != "safety" &&
          obj?.name != "amenity" &&
          obj?.name != "vertical" &&
          obj?.name != "boundary" &&
          canvas.getZoom() <= 0.35
        ) {
          if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
            // console.log(zoom, "0.6");
            obj.set({ opacity: 0.6 });
          } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
            obj.set({ opacity: 0.3 });
            // console.log(zoom, "0.3");
          } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
            obj.set({ opacity: 0.1 });
            // console.log(zoom, "0.1");
          } else if (canvas.getZoom() < 0.1) {
            // console.log(zoom, "0");
            obj.set({ opacity: 0 });
          }
        } else if (obj?.name != "boundary") {
          obj.set({ opacity: 1 });
        }
        canvas.requestRenderAll();
      });
    }

    setCanvas(canvas);
    let initialDistance = null;
    let zoom = canvas.getZoom();

    const transformToCanvasCoordinates = (point, canvas) => {
      const transform = canvas.viewportTransform;
      const zoom = canvas.getZoom();
      return {
        x: (point.x - transform[4]) / zoom,
        y: (point.y - transform[5]) / zoom
      };
    };


    const handleTouchMove = (e) => {
      setOpenLocation(false);
      setOpenProduct(false);
      setOpenOtherPins(false);
      setId();
      clicked = undefined
      if (e.touches.length === 1) {
        addApointToRotate(canvas)
      }

      if (e.touches.length === 2) {
        applyPinchZoom();
        e.preventDefault();

        const touch1 = e.touches[0];
        const touch2 = e.touches[1];

        const midpoint = {
          x: (touch1.clientX + touch2.clientX) / 2,
          y: (touch1.clientY + touch2.clientY) / 2,
        };

        const newDistance = getDistance(touch1, touch2);
        if (initialDistance) {

          const isZoomingOut = newDistance < initialDistance;
          const exponent = isZoomingOut ? 0.1 : 0.05;

          const scaleMultiplier = Math.pow(
            newDistance / initialDistance,
            exponent
          );
          let newZoom = zoom * scaleMultiplier;

          if (newZoom > 20) {
            newZoom = 20;
          } else if (newZoom < 0.05) {
            newZoom = 0.05;
          }

          canvas.zoomToPoint(new fabric.Point(midpoint.x, midpoint.y), newZoom);
          zoom = newZoom;
        }

        // canvas rotation functions
        const point1 = transformToCanvasCoordinates({ x: touch1.clientX, y: touch1.clientY }, canvas);
        const point2 = transformToCanvasCoordinates({ x: touch2.clientX, y: touch2.clientY }, canvas);

        const fixedPoint = {
          x: (point1.x + point2.x) / 2,
          y: (point1.y + point2.y) / 2
        };

        const newAngle = getAngleBetweenTouches(e.touches);
        const angleDiff = newAngle - initialAngle;

        addApointToRotate(canvas, fixedPoint)

        rotateCanvas(angleDiff, canvas, fixedPoint);
        initialAngle = newAngle;
        // -----------------------
      }
    };

    const handleTouchEnd = (e) => {
      if (e.touches.length < 2) {
        initialDistance = null;
        zoom = canvas.getZoom(); // Update zoom to final value
        // canvas rotation functions
        initialTouchAngle = 0;
        initialAngle = 0;
        // -----------------------
      }
    };

    const handleTouchStart = (e) => {
      console.log("touch start")
      if (e.touches.length === 2) {
        initialDistance = getDistance(e.touches[0], e.touches[1]);
        initialAngle = getAngleBetweenTouches(e.touches);

      }
    };

    function getDistance(touch1, touch2) {
      return Math.sqrt(
        (touch1.clientX - touch2.clientX) ** 2 +
        (touch1.clientY - touch2.clientY) ** 2
      );
    }

    canvas.upperCanvasEl.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });
    canvas.upperCanvasEl.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    canvas.upperCanvasEl.addEventListener("touchend", handleTouchEnd);
  };




  function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  const removeHighlightObj = (type) => {
    canvas?.forEachObject(function (obj) {
      if (obj?.types == type) {
        canvas.remove(obj);
      }
    });
  };

  const dragToPoint = (point) => {
    canvas.setZoom(1); // reset zoom so pan actions work as expected
    let vpw = canvas.width / 1;
    let vph = canvas.height / 1;
    let x = point.x - vpw / 2; // x is the location where the top left of the viewport should be
    let y = point.y - vph / 2; // y idem
    canvas.absolutePan({ x, y });
    canvas.setZoom(1);
  };

  const getFloorDetails = async (floorId) => {
    try {
      const response = await getRequest(`floor-data/${floorId}`);
      const data = response.data.floor_data ?? {};

      console.log(data, "datadata");
      const tracings = data?.tracings ? JSON.parse(data?.tracings ?? []) : [];
      const tracingCircle = data?.circle_data
        ? JSON.parse(data?.circle_data ?? [])
        : [];
      const texts = data?.text ? JSON.parse(data?.text ?? []) : [];
      const locations = data?.location_data?.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions),
      }));
      const products = data?.product_data?.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions),
      }));
      const beacons = data?.beacon_data?.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions),
      }));
      const amenities = data?.amenity_data?.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions),
      }));
      const safeties = data?.safeties_data?.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions),
      }));
      const vercalTransports = data?.vertical_transports?.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions),
      }));
      const floorImage = data?.cropped_path ? image_url + data?.cropped_path : null      
      // canvasBackGroundImage(canvas, floorImage, data)
      setProjectSettings(data?.project_settings);
      canvasBackGroundColor(canvas, data?.project_settings?.background_color);
      reinitialiseFabric(canvas);

      renderTracingCircle(canvas, projectSettings, tracingCircle ?? []);
      renderTracings(canvas, projectSettings, tracings ?? []);

      renderTexts(canvas, texts ?? []);
      renderBeacons(
        beacons ?? [],
        data?.project_settings,
        canvas,
        clickhandler
      );
      renderLocations(
        locations ?? [],
        data?.project_settings,
        canvas,
        clickhandler
      );
      if (from?.type == 2 || to?.type == 2) {
        renderProducts(
          products ?? [],
          from,
          to,
          canvas,
          data?.project_settings
        );
      }
      renderAmenities(
        amenities ?? [],
        canvas,
        data?.project_settings,
        clickhandler
      );
      renderSafeties(
        safeties ?? [],
        canvas,
        data?.project_settings,
        clickhandler
      );
      renderVerticalTransport(
        vercalTransports ?? [],
        canvas,
        data?.project_settings
      );
      if (from) {
        highlightStartAndEndPoints(from, data?.project_settings);
      }
      pinNameTextBringtoFront(canvas);
      canvasBackGroundImage(canvas, floorImage, data)
    } catch (error) {
      console.error(error);
    } finally {
      // canvasBackGroundImage(canvas, floorplanDummy)
    }
  };

  // use to control the pop up on canvas items beacons, amenity, location... click
  const clickhandler = (event, item) => {

    localStorage.removeItem("vertical");
    if (item?.item_type && item?.name !== "tracing" && item?.name !== "product") {

      if (item?.item_type === "1") {
        setOpenLocation(true);
        setId(item?.enc_id);
      }
      else if (item?.item_type === "2") {
        setOpenProduct(true);
        setId(item?.enc_id);
      } else {
        if (item?.item_type == 6) {
          let VTdata = {
            from: item?.name,
            from_id: item?.enc_id,
            from_floor_plan: item?.floor_plan,
            type: item?.type,
            from_floor_plan_id: item?.enc_floor_plan_id,
            from_type_name: "vertical",
            from_draft_id: item?.draft_id,
          };

          localStorage.setItem("vertical", JSON.stringify(VTdata));
        }
        setOpenOtherPins(true);
        setId(item?.enc_id);
        setOtherPinType(item?.item_type);
      }
    } else {
      closeOpenedPopUps()
    }
  };

  const closeOpenedPopUps = () => {
    setOpenLocation(false);
    setOpenProduct(false);
    setOpenOtherPins(false);
    setId();
    // console.log("closed")
  }


  useLayoutEffect(() => {
    initialiseFabric();
  }, []);

  useEffect(() => {
    if (canvas && !from?.from_id) {
      console.log(from, 'removeHighlightColor')
      removeHighlightColor();
    }
    if (canvas && currentFloor) {
      console.log(from, 'fromuseEffect')
      getFloorDetails(currentFloor);
    }
  }, [canvas, currentFloor, from]);

  // useEffect(() => {
  //     console.log(projectSettings, 'highlights')
  //     if (from &&projectSettings) {
  //         // setTimeout(() => {
  //             // removeHighlightObj('highlight_pin')
  //             highlightStartAndEndPoints(from)
  //         // }, 600);
  //     }
  // }, [(canvas, from, projectSettings)])

  const highlightStartAndEndPoints = (fromData, projectSettings) => {
    canvas?.forEachObject(function (obj) {
      if (obj?.name != "text" && obj?.name != "tracing") {
        if (fromData?.type != 6) {
          if (
            obj.enc_id == fromData?.from_id &&
            obj?.draft_id == fromData?.from_draft_id
          ) {
            if (obj?.name !== "boundary") {
              dragToPoint(obj?.position);
            }
            fromPinRender(obj, "from", projectSettings);
          }
        } else {
          if (obj?.draft_id == fromData?.from_draft_id) {
            if (obj?.name !== "boundary") {
              dragToPoint(obj?.position);
            }
            fromPinRender(obj, "from", projectSettings);
          }
        }
      }
    });
  };

  const changeObjectColorById = (object, newColor) => {
    if (object) {
      object.set({ types: "highlight_pin" });
      object.set({ highLightColor: newColor });
      if (object.type === "path") {
        object.set({ fill: newColor });
      } else if (object.type === "group") {
        object.getObjects().forEach((subObj) => {
          if (subObj.type === "path" && subObj.fill === object?.initialColor) {
            subObj.set({ fill: newColor });
          }
          if (subObj.type === "rect" && subObj.stroke === "transparent") {
            subObj.set({ stroke: newColor });
          }
        });
      }
      canvas.renderAll();
    } else {
      console.error("Object with the specified ID not found on the canvas.");
    }
  };

  const removeHighlightColor = () => {
    let object = findHighlightedObj("highlight_pin");
    let boundaryObject = findHighlightedObjBoundary("highlight_pin");
    console.log(boundaryObject, "boundaryObject");
    if (object) {
      const newColor = object?.initialColor;
      object.set({ types: null });
      if (object.type === "path") {
        object.set({ fill: newColor });
      } else if (object.type === "group") {
        object.getObjects().forEach((subObj) => {
          if (
            subObj.type === "path" &&
            subObj.fill === object?.highLightColor
          ) {
            subObj.set({ fill: newColor });
          }
          if (
            subObj.type === "rect" &&
            subObj.stroke === object?.highLightColor
          ) {
            subObj.set({ stroke: "transparent" });
          }
        });
      }
      canvas.renderAll();
    }
    if (boundaryObject) {
      object.set({ types: null });
      boundaryObject.set({ opacity: 0 });
      canvas.renderAll();
    }
  };

  const findHighlightedObj = (types) => {
    let objectFound = null;
    canvas?.forEachObject((obj) => {
      if (obj?.types === types) {
        objectFound = obj;
      }
    });
    return objectFound;
  };

  const findHighlightedObjBoundary = (types) => {
    let objectFound = null;
    canvas?.forEachObject((obj) => {
      if (obj?.types === types && obj?.name === "boundary") {
        objectFound = obj;
      }
    });
    return objectFound;
  };

  const fromPinRender = async (obj, type, projectSettings) => {
    let floor_id;
    setProjectSettings((prev) => {
      console.log(prev, "prev");
      floor_id = prev;
      // return prev;
    });
    if (obj.name === "location") {
      removeHighlightColor();
      changeObjectColorById(
        obj,
        type === "from" ? projectSettings?.start_color ?? "#5FD827" : obj?.color
      );
    } else if (obj.name === "boundary") {
      removeHighlightColor();
      setTimeout(() => {
        obj.set({ opacity: 1 });
        obj.set({ types: "highlight_pin" });
        console.log(obj, "boundary");
        canvas.renderAll();
      }, 500);
    } else if (obj.name === "product") {
      console.log(obj, "obj");
      removeHighlightColor();
      changeObjectColorById(
        obj,
        type === "from"
          ? projectSettings?.start_color ?? "#5FD827"
          : projectSettings?.product_color
      );
    } else if (obj.name === "beacon") {
      console.log(projectSettings?.start_color, 'highlightStartAndEndPoints')
      removeHighlightColor();
      changeObjectColorById(
        obj,
        type === "from"
          ? projectSettings?.start_color ?? "#5FD827"
          : projectSettings?.beacon_color
      );
    } else if (obj.name === "amenity") {
      changeObjectColorById(
        obj,
        type === "from"
          ? projectSettings?.start_color ?? "#5FD827"
          : projectSettings?.amenity_color
      );
    } else if (obj.name === "safety") {
      changeObjectColorById(
        obj,
        type === "from"
          ? projectSettings?.start_color ?? "#5FD827"
          : projectSettings?.safety_color
      );
    } else if (obj.name === "vertical") {
      addVerticalPin(
        obj,
        type === "from"
          ? projectSettings?.start_color ?? "#5FD827"
          : projectSettings?.level_change_color,
        canvas
      );
    }
  };

  return (
    <>
      <div>
        <SetDestination
          open={openLocation}
          setOpen={setOpenLocation}
          type={"destination"}
          id={id}
          closes={close}
          setCurrentFloor={setCurrentFloor}
          setFromDestinationData={setFromDestinationData}
        />
        <SetProjuctDestination
          open={openProduct}
          setOpen={setOpenProduct}
          type={"destination"}
          id={id}
          closes={close}
          setCurrentFloor={setCurrentFloor}
          setFromDestinationData={setFromDestinationData}
        />
        <SetLocation
          open={openOtherPins}
          setOpen={setOpenOtherPins}
          id={id}
          type={otherPinType}
          destination={"destination"}
          closes={close}
          setCurrentFloor={setCurrentFloor}
          setFromDestinationData={setFromDestinationData}
        //   click={click}
        />
        <canvas id="canvas" />
      </div>
    </>
  );
};
export default FabricCanvas;
